import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Layout from "@/Layout/index.vue";
import { useStore } from "vuex";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Layout,
    children: [
      {
        path: "/government",
        name: "government",
        component: () =>
          import(
            /* webpackChunkName: "government" */ "@/views/government/index.vue"
          ),
      },
      {
        path: "/socialOrganization",
        name: "socialOrganization",
        component: () =>
          import(
            /* webpackChunkName: "socialOrganization" */ "@/views/socialOrganization/index.vue"
          ),
      },
      {
        path: "/enterprise",
        name: "enterprise",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "@/views/enterprise/index.vue"
          ),
      },
    ],
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果目标路由指定了滚动行为，则返回该行为
    if (to.hash) {
      return { el: to.hash };
    }
    // 如果前一个路由有滚动位置，则返回该位置
    if (savedPosition) {
      return savedPosition;
    }
    // 否则返回顶部
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  console.log(store.getters.device);
  next();
});

export default router;
