<template>
  <div class="enterprise_dynamics">
    <span>企业动态</span>
    <span>合作共赢天下</span>
    <div class="content">
      <swiper
        :navigation="true"
        :cssMode="true"
        :pagination="true"
        :mousewheel="true"
        :keyboard="true"
        :loop="true"
        :autoplay="{ delay: 2000 }"
        class="mySwiper"
        :modules="modules"
      >
        <swiper-slide v-for="(item, index) in swiperContentList">
          <div class="item">
            <img :src="item.image" alt="" />
            <div class="article">
              <span>{{ item.art_one }}</span>
              <span>{{ item.art_two }}</span>
              <span>{{ item.art_three }}</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import person_one from "@/static/person_one.png";
import person_two from "@/static/person_two.png";
import person_three from "@/static/person_three.png";
import person_four from "@/static/person_four.png";
import person_five from "@/static/person_five.png";

const modules = reactive([
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
]);
const swiperContentList = reactive([
  {
    image: person_one,
    art_one: "部委合作",
    art_two: "工业和信息化部工业文化发展中心",
    art_three:
      "与工信部工业文化发展中心合作，聚米优选成为其指定合作服务商，运营食品文化巡礼线上小程序，搭建平台及协调通道，协助企业与各相关渠道方进行全方位对接，促进多行业品牌化渗透，实现流量消费力充分转化，推广食品产销新业态，",
  },
  {
    image: person_two,
    art_one: "地方政府合作",
    art_two: "吉林省松源市江区人民政府",
    art_three:
      "与吉林省松源市江区人民政府签署经贸合作，聚米优选利用其线上平台运营，资源整合优势，对接地方政府招商引资通道，与地方特色食品企业合作，搭建生产端和流通端企业合作桥梁，促进地方品牌企业增收，为地方品牌企业赋能，促进其数字化转型升级。",
  },
  {
    image: person_three,
    art_one: "社会团体合作",
    art_two: "浙江省商贸业联合会",
    art_three:
      "浙商联与聚米优选坚持“整合资源、发挥优势、协同创新、持续发展”的原则，整合现有资源，赋能企业转型升级，实现互利共赢、共同发展的目标",
  },
  {
    image: person_four,
    art_one: "支付宝平台合作",
    art_two: "支付宝  蚂蚁集团",
    art_three:
      "与支付宝平台合作，线上通过商品供给服务平台会员体系用户，线下通过AR门店互动的方式，为平台引流，实现互利共赢",
  },
  {
    image: person_five,
    art_one: "企业成果里程碑",
    art_two: "2023年“吃货节”启动仪式在京举行",
    art_three:
      "启动仪式上，工业和信息化部工业文化发展中心发布了“食品文化巡礼”活动仪式。且中国保护消费者基金会食品工作委员会、北京第一时间网络科技有限公司、聚米优选（北京）科技有限公司代表相关各界畅谈心声并向“吃货节”活动献上祝福。",
  },
]);
</script>
<style lang="less" scoped>
.swiper {
  width: 1200px;
  height: 347px;

  .swiper-slide {
    .item {
      display: flex;
      align-items: center;
      img {
        width: 380px;
        height: 347px;
      }
      .article {
        width: 624px;
        height: 327px;
        background-color: rgba(253, 21, 34, 0.05);
        border-radius: 8px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        box-sizing: border-box;
        margin-bottom: 8px;

        & > span:first-child {
          font-size: 34px;
          font-weight: 600;
          color: #333333;
          line-height: 48px;
        }

        & > span:nth-child(2) {
          margin-top: 16px;
          display: inline-block;
          padding: 6px 16px;
          width: 260px;
          height: 32px;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          position: relative;
          z-index: 10;
          box-sizing: border-box;

          &::before {
            content: "";
            position: absolute;
            display: inline-block;
            z-index: -1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(270deg, #ff0010 0%, #ff675d 100%);
            border-radius: 8px;
          }
        }
        & > span:nth-child(3) {
          margin-top: 24px;
          width: 544px;
          height: 72px;
          font-size: 14px;
          font-weight: 500;
          color: #666666;
          line-height: 24px;
        }
      }
    }
  }

  ::v-deep .swiper-button-next {
    width: 60px;
    height: 60px;
    background: rgba(253, 21, 34, 0.1);
    border-radius: 8px;
    position: absolute;
    &::after {
      content: "";
      background: url("@/static/pos_right.png");
      background-size: 27px 30px;
      display: block;
      width: 27px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -13.5px;
      margin-top: -15px;
    }
  }
  ::v-deep .swiper-button-prev {
    width: 60px;
    height: 60px;
    background: rgba(253, 21, 34, 0.1);
    border-radius: 8px;
    position: absolute;
    &::after {
      content: "";
      background: url("@/static/pos_left.png");
      background-size: 27px 30px;
      display: block;
      width: 27px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -13.5px;
      margin-top: -15px;
    }
  }
  ::v-deep .swiper-pagination {
    height: 20px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -70px;
    span {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: rgba(253, 21, 34, 1);
      transition: all 0.5s;
    }
    .swiper-pagination-bullet-active {
      width: 67px;
    }
  }
}
.enterprise_dynamics {
  height: 743px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span:first-child {
    font-size: 42px;
    font-weight: 500;
    color: #000000;
    line-height: 59px;
  }
  & > span:nth-child(2) {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
    opacity: 0.5;
    margin-top: 12px;
  }
  .content {
    margin-top: 44px;
    width: 1200px;
  }
}
</style>
