const state = {
  device: "PC",
};

const mutations = {
  TOGGLE_DEVICE: (state: any, device: String) => {
    state.device = device;
  },
};

const actions = {
  toggleDevice({ commit }: any, device: String) {
    commit("TOGGLE_DEVICE", device);
  },
};

export default {
  state,
  mutations,
  actions,
};
