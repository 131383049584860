<template>
  <div class="food_culture">
    <div class="pc_food_culture">
      <span class="inline-block">食品文化巡礼招商入口</span>
      <span class="inline-block">
        <img src="@/static/food_logo.png" alt="" />
      </span>
      <div class="food_entry">
        <router-link to="/government">
          <a class="food_entry_one">
            <img src="@/static/food_text_one.png" alt="" />
            <div class="bg"></div>
          </a>
        </router-link>
        <router-link to="/socialOrganization">
          <a class="food_entry_two">
            <img src="@/static/food_text_two.png" alt="" />
            <div class="bg"></div>
          </a>
        </router-link>
        <router-link to="/enterprise">
          <a class="food_entry_three">
            <img src="@/static/food_text_three.png" alt="" />
            <div class="bg"></div>
          </a>
        </router-link>
      </div>
      <div class="food_bottom">
        <span>食品文化巡礼企微答疑群号：18072830814</span>
        <p>
          食品文化巡礼服务热线：
          <span>18072830814</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style lang="less" scoped>
.food_culture {
  .pc_food_culture {
    height: 943px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > span:first-child {
      width: 420px;
      height: 59px;
      font-size: 42px;
      font-weight: 500;
      color: #000000;
      line-height: 59px;
    }
    & > span:nth-child(2) {
      margin: 40px 0;
      img {
        width: 331px;
        height: 94px;
      }
    }
    .food_entry {
      display: flex;
      .food_entry_one,
      .food_entry_two,
      .food_entry_three {
        width: 416.5px;
        height: 437px;
        position: relative;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          transition: 0.4s;

          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
        & > img {
          width: 158px;
          height: 118px;
          z-index: 10;

          &:hover + .bg {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .food_entry_one {
        background-image: url("@/static/food_bg_one.png");
      }
      .food_entry_two {
        background-image: url("@/static/food_bg_two.png");
        & > img {
          width: 278px;
          height: 118px;
        }
      }
      .food_entry_three {
        background-image: url("@/static/food_bg_three.png");
      }
    }
    .food_bottom {
      width: 1250px;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      & > span {
        white-space: nowrap;
      }
      p {
        span {
          color: rgba(253, 14, 28, 1);
          text-decoration-line: underline;
        }
      }
    }
  }
}
</style>
