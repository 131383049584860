import { onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";

const { body } = document;
const WIDTH = 992; // refer to Bootstrap's responsive design

export default function useResize() {
  const store = useStore();
  //   watch(
  //     () => router.currentRoute.value,
  //     (to, from) => {
  //       const device = store.getters.device;
  //       if (device.value) {
  //       }
  //     }
  //   );
  onBeforeMount(() => {
    window.addEventListener("resize", $_resizeHandler);
    if ($_isMobile()) {
      store.dispatch("toggleDevice", "Mobile");
      window.location.href = "http://jumyoux.cn/h5/#/";
    }
  });
  onBeforeUnmount(() => {
    window.removeEventListener("resize", $_resizeHandler);
  });

  const $_isMobile = () => {
    const rect = body.getBoundingClientRect();
    return rect.width - 1 < WIDTH;
  };

  const $_resizeHandler = () => {
    if (!document.hidden) {
      const isMobile = $_isMobile();
      store.dispatch("toggleDevice", isMobile ? "Mobile" : "PC");
      if (isMobile) {
        window.location.href = "http://jumyoux.cn/h5/#/";
      }
    }
  };
}
