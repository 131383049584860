// 阿里云图片资源
export default {
  // index:
  //   "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/index.png",
  jum_header_logo:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E8%81%9A%E7%B1%B3logo.png",
  index:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/index.jpg",
  index_video:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/index_video.mp4",
  cange:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/cange.png",
  erweima:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/erweima%402x.png",
  feige:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/feige.jpg",
  haoge:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/haoge.jpg",
  huangzong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/huangzong.jpg",
  icon_O2O_nor:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_020_nor%402x.png",
  icon_O2O_hiligt:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_020_hiligt%402x.png",
  icon_ARhudong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_ARhudong%402x.png",
  icon_cange:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_cange%402x.png",
  icon_feige:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_feige%402x.png",
  icon_gongyinglian:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_gongyinglian%402x.png",
  icon_haige:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_haige%402x.png",
  icon_haoge:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_haoge%402x.png",
  icon_huangzong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_huangzong%402x.png",
  icon_jifentixi:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_jifentixi%402x.png",
  icon_lingjie:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_lingjie%402x.png",
  icon_linian_hiligt:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_linian_hilight%402x.png",
  icon_linian_nor:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_linian_nor%402x.png",
  icon_lvzong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_lvzong%402x.png",
  icon_majing:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_majing%402x.png",
  icon_minghai:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_minghai%402x.png",
  icon_ouge:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_ouge%402x.png",
  icon_sanfangnengli:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_sanfangnengli%402x.png",
  icon_shange:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_shange%402x.png",
  icon_diamond:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_supliers%402x.png",
  icon_tangzong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_tangzong%402x.png",
  icon_tiktok_nor:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_tiktok%402x.png",
  icon_tiktok_hiligt:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_tiktok_hilight%402x.png",
  icon_value_hiligt:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_value_hilight%402x.png",
  icon_value_nor:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_value_nor%402x.png",
  icon_wechat_nor:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_wechat%402x.png",
  icon_wechat_hiligt:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_wechat_hilight%402x.png",
  icon_weibo_nor:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_weibo%402x.png",
  icon_weibo_hiligt:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_weibo_hilight%402x.png",
  icon_xiaohongshu_hiligt:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_xiaohongshu_hilight%402x.png",
  icon_yifan:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_yifan%402x.png",
  icon_yuanjing_hiligt:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_yuanjing_hilight%402x.png",
  icon_yuanjing_nor:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_yuanjing_nor%402x.png",
  icon_zhangzong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_zhangzong%402x.png",
  icon_zhouyuan:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/icon_zhouyuan%402x.png",
  lingjie:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/lingjie.png",
  logo: "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/logo%402x.png",
  lvzong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/lvzong.jpg",
  majing:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/majing.jpg",
  map: "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/map%402x.png",
  haige:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/minghai.png",
  ouge: "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/ouge.jpg",
  pingtaimoshi:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/pingtaimoshi%402x.png",
  pingtaiyoushi:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/pingtaiyoushi%402x.png",
  shange:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/shange.jpg",
  tangzong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/tangzong.jpg",
  weitu_footer:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/weitu1%402x.png",
  weitu_jumyoux:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/weitu2%402x.png",
  weitu_background:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/weitu%402x.png",
  yifan:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/yifan.jpg",
  yunyingmoshi:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/yunyingmoshi%402x.png",
  zhangzong:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/zhangzong.jpg",
  zhouyuan:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/zhouyuan.jpg",
  logo_banshe:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E5%8D%8A%E8%88%8D%E5%85%A8%E5%B1%8B%E5%A4%B4%E5%83%8F.jpg",
  hezuoyaoqiu:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/hezuoyaoqiu.jpg",
  gongsigaishu:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/gongsigaishu.jpg",
  erweima_x12:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/erweima%40x12.jpg",
  erweima_x8:
    "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/erweima%40x8.jpg",
};
