<template>
  <div class="business_overview">
    <span>业务概况</span>
    <span>打造线上线下AR互动平台</span>
    <div class="content">
      <div class="left">
        <div class="left_one wow bounceInLeft">
          <span>提供平台会员体系产品化服务能力</span>
          <span>提供全链路的供给服务能力</span>
          <img src="@/static/left_one.png" alt="" />
        </div>
        <div class="left_two wow bounceInLeft" data-wow-offset="100">
          <span>合作阿里体系平台及其他平台会员</span>
          <img src="@/static/left_one.png" alt="" />
        </div>
      </div>
      <div class="center">
        <div class="center_content">
          <img src="@/static/center_one.png" alt="" />
          <div>
            <span>新模式 新消费 新场景</span>
            <span>助力数字经济新发展</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right_one wow bounceInRight">
          <span
            >线下互动场景体验，获取权益价值；线上传播渗透；打造互动式、沉浸式、多元化用户消费体验店</span
          >
          <img src="@/static/right_one.png" alt="" />
        </div>
        <div class="right_two wow bounceInRight" data-wow-offset="100">
          <span>已有线下AR互动店</span>
          <span>杭州银泰西湖文化广场店</span>
          <span>杭州银泰武林广场店</span>
          <img src="@/static/right_one.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style lang="less" scoped>
.business_overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1004px;
  background: url("@/static/weitu@2x.png") no-repeat;
  background-size: 100% 100%;
  img {
    width: 50px;
    height: 50px;
  }

  & > span:first-child {
    font-size: 42px;
    font-weight: 500;
    color: #000000;
    line-height: 59px;
  }
  & > span:nth-child(2) {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
    margin-top: 12px;
    opacity: 0.5;
  }
  .content {
    height: 624px;
    margin-top: 70px;
    display: flex;

    .left {
      .left_one,
      .left_two {
        background: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(173, 173, 173, 0.13);
        border-radius: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        padding: 24px 24px 16px 24px;
        position: relative;

        span {
          display: inline-block;
        }

        img {
          position: absolute;
          left: 16px;
          top: -25px;
        }
      }
      .left_one {
        width: 230px;
        height: 128px;
        margin-top: 36px;
      }
      .left_two {
        width: 230px;
        height: 96px;
        margin-top: 116px;
      }
    }
    .center {
      width: 658px;
      height: 624px;
      margin-left: 56px;
      background: url("@/static/center_pos.png") no-repeat;
      background-size: 100% 100%;
      position: relative;

      .center_content {
        width: 422px;
        height: 98px;
        background: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(173, 173, 173, 0.13);
        border-radius: 10px;
        position: absolute;
        padding: 24px 24px 24px 10px;
        display: flex;
        left: 125px;
        bottom: 37px;
        & > div {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          justify-content: center;

          & > span:first-child {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 32px;
          }

          & > span:nth-child(2) {
            width: 126px;
            height: 28px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
          }
        }
      }
    }
    .right {
      margin-left: 32px;

      .right_one,
      .right_two {
        position: relative;
        padding: 24px 24px 16px 24px;
        img {
          position: absolute;
          left: 16px;
          top: -25px;
        }
      }
      .right_one {
        width: 230px;
        height: 152px;
        background: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(173, 173, 173, 0.13);
        border-radius: 10px;
        & > span {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
        }
      }
      .right_two {
        width: 230px;
        height: 124px;
        background: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(173, 173, 173, 0.13);
        border-radius: 10px;
        margin-top: 96px;
        span {
          display: inline-block;
        }

        & > span:first-child {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
        }
        & > span:nth-child(2),
        span:nth-child(3) {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
