<template>
  <div class="container">
    <header>
      <div class="header_left">
        <img class="logo" :src="source.jum_header_logo" alt="logo" />
        <nav>
          <ul class="tab_nav clearfix">
            <li>
              <a
                :class="{ tab_active: currentIndex === 'container' }"
                @click.prevent="tabActive('container')"
                ref="index"
                class="nav_a"
                href="javascript:void(0);"
                >首页</a
              >
            </li>
            <li>
              <a
                :class="{
                  tab_active: currentIndex === 'food_culture',
                }"
                @click.prevent="tabActive('food_culture')"
                ref="about_us"
                class="nav_a"
                href="javascript:void(0);"
              >
                <span>HOT</span>食品文化巡礼</a
              >
            </li>
            <li>
              <a
                :class="{ tab_active: currentIndex === 'company_overview' }"
                @click.prevent="tabActive('company_overview')"
                ref="company_overview"
                class="nav_a"
                href="javascript:void(0);"
                >公司概述</a
              >
            </li>
            <li>
              <a
                :class="{
                  tab_active: currentIndex === 'business_overview',
                }"
                @click.prevent="tabActive('business_overview')"
                ref="business_overview"
                class="nav_a"
                href="javascript:void(0);"
                >业务概况</a
              >
            </li>
            <li>
              <a
                :class="{ tab_active: currentIndex === 'enterprise_dynamics' }"
                @click.prevent="tabActive('enterprise_dynamics')"
                ref="enterprise_dynamics"
                class="nav_a"
                href="javascript:void(0);"
                >企业动态</a
              >
            </li>
            <!-- <li>
              <a
                :class="{ tab_active: currentIndex === 'corporate_culture' }"
                @click.prevent="tabActive('corporate_culture')"
                ref="corporate_culture"
                class="nav_a"
                href="javascript:void(0);"
                >企业文化</a
              >
            </li> -->
          </ul>
        </nav>
      </div>

      <span class="inline-block">
        <a class="inline-block" href="javascript:void(0);">
          <img class="header_img_big_d" src="@/static/bigD.png" alt="" />
          <span class="inline-block">
            <img src="@/static/shedui.jpg" alt="" />
          </span>
        </a>
        <a class="inline-block" href="javascript:void(0);">
          <img class="header_img_wechat" :src="source.icon_wechat_nor" alt="" />
          <span class="inline-block">
            <img :src="source.erweima_x12" alt="" />
          </span>
        </a>
        <a
          class="inline-block"
          href="https://v.douyin.com/SmBDLhS/"
          target="_blank"
        >
          <img class="header_img_tiktok" :src="source.icon_tiktok_nor" alt="" />
        </a>
        <a
          class="inline-block"
          href="https://weibo.com/u/7796990654"
          target="_blank"
        >
          <img class="header_img_weibo" :src="source.icon_weibo_nor" alt="" />
        </a>
      </span>
    </header>
    <article v-if="currentPath === '/'">
      <div class="video-container">
        <!-- <video
          class="fullscreenVideo"
          id="bgVid"
          :autoplay="true"
          :loop="true"
          muted
          width="100%"
          src="https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/foodhome.jpg"
          poster="https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/foodhome.jpg"
          x5-video-player-fullscreen="true"
          x5-playsinline
          playsinline
          webkit-playsinline
        >
          您的浏览器不支持 video 标签。
        </video> -->
        <el-carousel :interval="3000" arrow="always" height="100%">
          <el-carousel-item v-for="(item, index) in carouselList" :key="index">
            <img class="index_image" :src="item.image" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="video_title">
          <span
            class="video_title_three inline-block no_select"
            @click="tabActive('food_culture')"
            >合作加入</span
          >
          <span class="video_title_one inline-block no_select"
            >2023中国“食品文化巡礼”活动启动</span
          >
          <!-- <span class="video_title_two inline-block no_select"
            >和我们一起，去携手和共赢未来无限可能</span
          > -->
        </div>
      </div>
      <div class="company_overview" name="company_overview">
        <!-- <img :src="source.gongsigaishu" alt="" /> -->
        <span>公司概述</span>
        <span>AR互动平台 快乐时间有价值</span>
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;聚米优选以各大互联网平台会员服务为起点，布局线下AR互动场景为核心的新业态模式，构建自己的用户体系，秉承“快乐时间有价值”的服务理念，服务于亿万用户。旗下子公司杭州聚米优选科技集团有限公司与工业和信息化部工业文化发展中心签署合作，将参与新业态产销对接，协助企业与各相关渠道方进行全方位对接，推广食品产销新业态
          。</span
        >
        <span></span>
        <span> </span>
        <div>
          <div>
            <img :src="source.icon_ARhudong" alt="" />
            <span>AR娱乐互动</span>
          </div>
          <div>
            <img :src="source.icon_jifentixi" alt="" />
            <span>权益积分体系</span>
          </div>
          <div>
            <img :src="source.icon_sanfangnengli" alt="" />
            <span>三方服务能力</span>
          </div>
          <div>
            <img :src="source.icon_gongyinglian" alt="" />
            <span>商货供应链</span>
          </div>
        </div>
      </div>
      <!-- 业务概括 -->
      <business-overview></business-overview>
      <!-- 企业动态 -->
      <enterprise-dynamics></enterprise-dynamics>
      <!-- <div class="corporate_culture">
        <span>企业文化</span>
        <span>与您企业合作共赢就是我们的目标</span>
        <div>
          <div class="four_block">
            <div class="four_block_one">
              <img :src="source.icon_O2O_nor" alt="" />
              <span>公司定位</span>
              <span>AR互动平台</span>
            </div>
            <div class="four_block_two">
              <img :src="source.icon_linian_nor" alt="" />
              <span>我们的使命</span>
              <span>快乐时间有价值</span>
            </div>
            <div class="four_block_three">
              <img :src="source.icon_yuanjing_nor" alt="" />
              <span>我们的愿景</span>
              <span>成为一家多元数智互动平台</span>
            </div>
            <div class="four_block_four">
              <img :src="source.icon_value_nor" alt="" />
              <span>我们的服务理念</span>
              <span>以服务为核心，向上，向善</span>
            </div>
          </div>
          <div class="weitu">
            <img
              class="wow bounceInDown"
              data-wow-offset="100"
              :src="source.weitu_jumyoux"
              alt=""
            />
            <div
              class="tu_left_top wow rotateInDownLeft"
              data-wow-offset="100"
            ></div>
            <div
              class="tu_right_bottom wow rotateInUpRight"
              data-wow-offset="100"
            ></div>
          </div>
        </div>
      </div> -->
      <!-- <div class="team_strength">
        <span class="inline-block">创始人宣言</span>
        <span class="inline-block">与您企业合作共赢就是我们的使命</span>
        <main>
          <div class="main_left">
            <span class="inline-block wow bounceInLeft" data-wow-offset="100"
              >和一群敢想、敢拼、坚持的人一起努力、成长、合作</span
            >
            <span class="inline-block wow rollIn" data-wow-offset="100"
              >加入我们吧</span
            >
          </div>
          <div class="main_right wow bounceInRight" data-wow-offset="100">
            <img :src="source.tangzong" alt="" />
            <div class="main_right_profile">
              <span class="inline-block">创始人：唐荣伟</span>
              <span class="inline-block">董事长兼CEO、执行董事</span>
            </div>
          </div>
        </main>
      </div> -->
      <!-- <div class="business_model">
        <span>我们的业务模式</span>
        <span>打造出线上线下互动流量平台</span>
        <main>
          <div class="business_model_main_left">
            <span
              data-wow-offset="100"
              class="inline-block wow bounceInLeft"
              :class="{
                business_model_active: currentModel === 'platform_mode',
              }"
              @mouseover="modelClick('platform_mode')"
              >平台模式</span
            >
            <span
              data-wow-offset="100"
              class="inline-block wow bounceInLeft"
              :class="{
                business_model_active: currentModel === 'operation_mode',
              }"
              @mouseover="modelClick('operation_mode')"
              >运营模式</span
            >
            <span
              data-wow-offset="100"
              class="inline-block wow bounceInLeft"
              :class="{
                business_model_active: currentModel === 'platform_advantages',
              }"
              @mouseover="modelClick('platform_advantages')"
              >平台优势</span
            >
          </div>
          <div
            class="business_model_main_right wow bounceInRight"
            data-wow-delay="0.5s"
            data-wow-offset="100"
          >
            <img
              v-show="currentModel === 'platform_mode'"
              :src="source.pingtaimoshi"
              alt=""
            />
            <img
              v-show="currentModel === 'operation_mode'"
              :src="source.yunyingmoshi"
              alt=""
            />
            <img
              v-show="currentModel === 'platform_advantages'"
              :src="source.pingtaiyoushi"
              alt=""
            />
          </div>
        </main>
      </div> -->
      <div class="cooperation_requirements">
        <!-- <div class="cooperation_requirements_top">
          <img :src="source.hezuoyaoqiu" alt="" />
        </div> -->
        <div class="cooperation_requirements_bottom">
          <img src="../static/supplier.svg" alt="" />
          <a
            class="inline-block"
            href="http://www.jumyoux.com/#/supplier/supplierSettlement"
            target="_blank"
          >
            <span class="inline-block"> 加入我们吧 </span>
          </a>
        </div>
      </div>
      <food-culture></food-culture>
      <div class="about_us">
        <span>联系我们</span>
        <span>心动不如行动，联系我们吧！</span>
        <div class="about_us_map">
          <img :src="source.map" alt="" />
          <div class="address">
            <div class="address_one">
              <span class="inline-block">地址</span>
              <span class="inline-block"
                >余杭区仓前街道文一西路1382号绿岸科创园1101</span
              >
            </div>
            <div class="address_two">
              <span class="inline-block">服务电话</span>
              <span class="inline-block">19357320853</span>
            </div>
            <div class="address_three">
              <span class="inline-block">商务合作邮箱</span>
              <span class="inline-block">banshe@jumyoux.com</span>
            </div>
          </div>
        </div>
        <!-- <div class="join_us">
          <span>成长的路上与您携手，情聚半舍，共赢天下</span>
          <span class="inline-block wow shake" data-wow-offset="50"
            >立即加入</span
          >
        </div> -->
      </div>
    </article>
    <article v-else>
      <router-view></router-view>
    </article>
    <footer>
      <div class="footer_left">
        <img :src="source.jum_header_logo" alt="" />
        <span class="inline-block">
          <!-- Copyright @2022 聚米优选 AII Rights Reserved -->
        </span>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >浙ICP备2022033339号-3</a
        >
      </div>
      <div class="footer_center">
        <span>招商合作</span>
        <span class="inline-block">
          <img src="https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91H5/cooperation.png" alt="" />
        </span>
        <span>热线：13798669379</span>
      </div>
      <div class="footer_right">
        <span>门店合作</span>
        <span class="inline-block">
          <img src="https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91H5/cooperation.png" alt="" />
        </span>
        <span>热线：13798669379</span>
      </div>
    </footer>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { WOW } from "wowjs";
import source from "./ImgSource";
import BusinessOverview from "./components/businessOverview/index.vue";
import EnterpriseDynamics from "./components/enterpriseDynamics/index.vue";
import FoodCulture from "./components/foodCulture/index.vue";
import { useRoute, useRouter } from "vue-router";
import { reactive } from "vue";
import index_image_two from "@/static/index_image_two.jpg";
import useResize from "@/composables/useResize";

useResize();

const store = useStore();

const device = computed(() => {
  return store.getters.device;
});

onMounted(() => {
  const wow = new WOW();
  wow.init();
});
const carouselList = reactive([
  {
    image:
      "https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/foodhome.jpg",
  },
  {
    image: index_image_two,
  },
]);
const currentIndex = ref("container");
const router = useRouter();
const tabActive = (tab: string) => {
  currentIndex.value = tab;
  // document
  //   .querySelector(`.${tab}`)
  //   ?.scrollIntoView({ block: "start", behavior: "smooth" });   //这种滚动方法会和swiper的自动播放冲突，所以使用下面这种

  if (currentPath.value === "/") {
    const element = document.querySelector(`.${tab}`) as HTMLElement;
    window.scrollTo({ top: element?.offsetTop, behavior: "smooth" });
  } else {
    router.push("/");
    setTimeout(() => {
      const element = document.querySelector(`.${tab}`) as HTMLElement;
      window.scrollTo({ top: element?.offsetTop, behavior: "smooth" });
    }, 100);
  }
};

// const currentModel = ref("platform_mode");
// const modelClick = (model: string) => {
//   currentModel.value = model;
// };

const route = useRoute();
const currentPath = computed(() => {
  return route.path;
});
</script>
<style lang="less" scoped>
@keyframes planet-rotate {
  0% {
    transform: rotate(45deg) rotate(0);
  }
  100% {
    transform: rotate(45deg) rotate(360deg);
  }
}

@keyframes self-rotate {
  0% {
    transform: rotate(0) rotate(-45deg);
  }
  100% {
    transform: rotate(-360deg) rotate(-45deg);
  }
}
.el-carousel {
  height: calc(100vh - 80px);

  .index_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.container {
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tab_active {
    color: #f3121f;
    &::after {
      content: "";
      display: inline-block;
      height: 3px;
      width: 100%;
      background-color: #f3121f;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 80px;
    padding: 0 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;

    .header_left {
      display: flex;
      align-items: center;
      .logo {
        width: 140px;
        height: 54px;
        cursor: pointer;
      }

      nav {
        margin-left: 60px;
        ul {
          margin: 0;
          padding: 0;
          li {
            float: left;
            margin-left: 60px;
            a {
              display: inline-block;
              height: 80px;
              line-height: 80px;
              position: relative;
            }
          }
          li:nth-child(2) {
            a {
              span {
                width: 32px;
                height: 17px;
                display: inline-block;
                background: rgba(243, 18, 31, 1);
                border-radius: 2px;
                padding: 4px 5px;
                margin-right: 4px;
                color: #ffffff;
                font-size: 10px;
                font-weight: 500;
                color: #ffffff;
                line-height: 10px;
                text-align: center;
              }
            }
          }
        }
      }
    }

    & > span {
      a {
        margin-left: 16px;

        & > img {
          width: 28px;
          height: 28px;
        }

        .header_img_big_d:hover {
          content: url("../static/bigD_hlight.png");
        }

        .header_img_wechat:hover {
          content: url("../static/icon_wechat_hilight@2x.png");
        }

        .header_img_tiktok:hover {
          content: url("../static/icon_tiktok_hilight@2x.png");
        }

        .header_img_weibo:hover {
          content: url("../static/icon_weibo_hilight@2x.png");
        }
      }

      a:first-child,
      :nth-child(2) {
        position: relative;

        &:hover span {
          display: flex;
        }

        span {
          position: absolute;
          top: 41px;
          left: calc(50% - 54px);
          background: #ffffff;
          box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.07);
          padding: 4px;
          display: none;
          justify-content: center;
          align-items: center;

          img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }

  .nav_a:hover {
    color: #f3121f;
  }

  .nav_a:hover::after {
    content: "";
    display: inline-block;
    height: 3px;
    width: 100%;
    background-color: #f3121f;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  article {
    padding-top: 80px;
    .video-container {
      height: calc(100vh - 80px);
      width: 100%;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        z-index: -999;
        top: 0;
        left: 0;
      }

      .fullscreenVideo {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video_title {
        position: absolute;
        height: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .no_select {
          user-select: none;
        }

        .video_title_one {
          width: 1095px;
          height: 88px;
          line-height: 88px;

          margin-bottom: 40px;
          text-align: center;

          font-size: 46px;
          font-weight: 500;
          color: #083d76;
          position: relative;
          z-index: 9;

          &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            z-index: -1;
            background: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.9) 14%,
              #ffffff 52%,
              rgba(255, 255, 255, 0.9) 88%,
              rgba(255, 255, 255, 0) 100%
            );
            filter: blur(4px);
          }
        }
        .video_title_two {
          font-size: 18px;
          line-height: 36px;
          margin-top: 13px;
        }

        .video_title_three {
          margin-bottom: 40px;
          width: 210px;
          height: 54px;
          font-size: 30px;
          line-height: 25px;
          // color: #ffffff;
          color: #000000;
          background-color: #ffffff;

          position: relative;
          text-align: center;
          line-height: 53px;
          z-index: 5;
          border: 2px solid #ffffff;
          border-radius: 27px;
          cursor: pointer;
          display: flex;
          justify-content: center;

          &:hover {
            color: #000000;
            border: 2px solid transparent;
          }

          &::before {
            content: "";
            width: 0;
            height: 100%;
            position: absolute;
            border-radius: 27px;
            top: 0;
            z-index: -1;
            transition: all 0.3s linear;
          }

          &:hover::before {
            content: "";
            background-color: #ffffff;
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 27px;
            top: 0;
            z-index: -1;
          }
        }
      }
    }
    .company_overview {
      height: 789px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 240px;

      span {
        color: #000000;
      }
      span:first-child {
        text-align: center;
        font-size: 42px;
        line-height: 59px;
        font-weight: 500;
      }
      span:nth-child(2) {
        text-align: center;
        font-size: 18px;
        line-height: 36px;
        opacity: 50%;
        margin-top: 12px;
      }
      span:nth-child(3) {
        font-size: 16px;
        line-height: 35px;
        opacity: 80%;
        margin-top: 50px;
      }
      span:nth-child(4) {
        font-size: 16px;
        line-height: 35px;
        opacity: 80%;
        margin-top: 26px;
      }
      span:nth-child(5) {
        font-size: 16px;
        line-height: 35px;
        opacity: 80%;
        margin-top: 26px;
      }
      div:nth-child(6) {
        display: flex;
        margin-top: 37px;
        padding: 0 270px;
        justify-content: space-between;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          img {
            width: 80px;
            height: 80px;
          }
          span {
            opacity: 100%;
          }
        }
      }
    }
    .company_overview {
      & > img {
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
      }
    }
    .corporate_culture {
      height: 897px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fafafa;

      span:first-child {
        font-size: 42px;
        line-height: 59px;
        font-weight: 500;
      }

      span:nth-child(2) {
        font-size: 18px;
        line-height: 36px;
        margin-top: 12px;
        opacity: 50%;
      }

      div:nth-child(3) {
        padding-top: 86px;
        display: flex;
      }

      .four_block {
        display: flex;
        width: 620px;
        height: 450px;
        flex-wrap: wrap;
        justify-content: space-between;

        .four_block_one:hover {
          img {
            content: url("../static/icon_020_hiligt@2x.png");
          }
        }
        .four_block_two:hover {
          img {
            content: url("../static/icon_linian_hilight@2x.png");
          }
        }
        .four_block_three:hover {
          img {
            content: url("../static/icon_yuanjing_hilight@2x.png");
          }
        }
        .four_block_four:hover {
          img {
            content: url("../static/icon_value_hilight@2x.png");
          }
        }
        img {
          width: 63px;
          height: 63px;
        }

        & > div {
          width: 294px;
          height: 209px;
          display: flex;
          padding: 30px 0 0 30px;
          flex-direction: column;
          background-color: #ffffff;
          border-radius: 10px;
          cursor: pointer;
          position: relative;
          overflow: hidden;

          &::after {
            content: "";
            width: 0;
            height: 5px;
            background: linear-gradient(90deg, #ff605a 0%, #ff0010 100%);
            box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
            position: absolute;
            left: 0;
            bottom: 0;
            transition: all 0.3s linear;
          }

          &:hover::after {
            content: "";
            width: 100%;
            height: 5px;
            background: linear-gradient(90deg, #ff605a 0%, #ff0010 100%);
            box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
            position: absolute;
            left: 0;
            bottom: 0;
          }

          span:nth-child(2) {
            font-size: 23px;
            line-height: 32px;
            font-weight: 500;
            margin-top: 20px;
          }
          span:nth-child(3) {
            font-size: 18px;
            line-height: 30px;
            opacity: 70%;
            margin-top: 4px;
          }
        }
      }
      .weitu {
        width: 602px;
        height: 451px;
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-left: 30px;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 999;
        }

        .tu_left_top {
          width: 247px;
          height: 284px;
          border-radius: 100px 10px 10px 10px;
          position: absolute;
          top: -50px;
          left: -4px;
          z-index: 10;
          background: linear-gradient(
            180deg,
            rgba(255, 96, 90, 0.05) 0%,
            rgba(255, 0, 16, 0.05) 100%
          );
          box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
        }

        .tu_right_bottom {
          width: 247px;
          height: 284px;
          align-self: flex-end;
          border-radius: 10px 10px 100px 10px;
          position: absolute;
          bottom: -41px;
          right: -28px;
          z-index: 10;
          background: linear-gradient(
            180deg,
            rgba(255, 96, 90, 0.05) 0%,
            rgba(255, 0, 16, 0.05) 100%
          );
          box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
        }
      }
    }
    .team_strength {
      height: 897px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > span:first-child {
        font-size: 42px;
        line-height: 59px;
        font-weight: 500px;
      }

      & > span:nth-child(2) {
        font-size: 18px;
        line-height: 36px;
        font-weight: 400;
        opacity: 50%;
        margin-top: 12px;
      }

      main {
        display: flex;
        margin-top: 35px;

        .main_left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 420px;
          & > span:first-child {
            font-size: 42px;
            line-height: 59px;
            font-weight: 400;
          }

          & > span:nth-child(2) {
            width: 210px;
            height: 54px;
            position: relative;
            z-index: 10;
            text-align: center;
            line-height: 54px;
            z-index: 99;
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            margin-top: 39px;

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              background: linear-gradient(140deg, #ff605a 0%, #ff0010 100%);
              box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
              border-radius: 27px;
              z-index: -1;
            }
          }
        }
        .main_right {
          width: 542px;
          height: 542px;
          margin-left: 174px;
          border-radius: 10px;
          overflow: hidden;
          cursor: pointer;
          position: relative;

          & > img {
            width: 100%;
            object-fit: cover;
          }

          .main_right_profile {
            width: 100%;
            height: 150px;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.6);
            display: flex;
            color: #000000;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 32px;

            span:first-child {
              font-weight: 550;
            }
          }

          .outer_circle {
            background-color: rgba(243, 220, 220, 0.11);
            border: 1px solid rgba(254, 139, 112, 0.11);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .inner_circle {
              position: relative;
              width: 358px;
              height: 358px;
              background: linear-gradient(
                150deg,
                rgba(255, 223, 203, 0.17),
                rgba(255, 125, 72, 0.17)
              );
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              // animation: planet-rotate 20s linear infinite;

              &:hover {
                animation-play-state: paused;
              }

              .inner_core {
                position: relative;
                width: 158px;
                height: 158px;
                background: linear-gradient(
                  140deg,
                  rgba(255, 96, 90, 0.6),
                  rgba(255, 0, 16, 0.6)
                );
                box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .inner_core_diamond {
                  width: 53px;
                  height: 46px;
                  // animation: self-rotate 20s linear infinite;
                }
              }

              &:hover .inner_core_diamond {
                animation-play-state: paused;
              }

              .popup {
                position: absolute;
                cursor: pointer;
                // animation: self-rotate 20s linear infinite;
              }

              &:hover .popup {
                animation-play-state: paused;
              }

              .popup0 {
                width: 90px;
                height: 90px;
                left: -40px;
              }

              .popup1 {
                width: 90px;
                height: 90px;
                left: 20px;
                bottom: 10px;
              }

              .popup2 {
                width: 90px;
                height: 90px;
                left: 20px;
                top: 10px;
              }

              .popup3 {
                width: 60px;
                height: 60px;
                left: 170px;
                top: 20px;
              }

              .popup4 {
                width: 60px;
                height: 60px;
                left: 245px;
                top: 70px;
              }

              .popup5 {
                width: 60px;
                height: 60px;
                left: 275px;
              }

              .popup6 {
                width: 60px;
                height: 60px;
                left: 245px;
                bottom: 70px;
              }

              .popup7 {
                width: 60px;
                height: 60px;
                left: 240px;
                top: -110px;
              }

              .popup8 {
                width: 60px;
                height: 60px;
                left: 350px;
                top: -30px;
              }

              .popup9 {
                width: 60px;
                height: 60px;
                left: 410px;
                top: 80px;
              }

              .popup10 {
                width: 60px;
                height: 60px;
                left: 410px;
                top: 200px;
              }

              .popup11 {
                width: 60px;
                height: 60px;
                left: 370px;
                top: 310px;
              }

              .popup12 {
                width: 60px;
                height: 60px;
                left: 285px;
                top: 385px;
              }

              .popup13 {
                width: 60px;
                height: 60px;
                left: 170px;
                top: 280px;
              }
            }
          }
        }
      }
    }

    .business_model {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 1001px;
      background: url("../static/weitu@2x.png") no-repeat;
      background-size: 100% 100%;
      span:first-child {
        font-size: 42px;
        line-height: 59px;
        font-weight: 500;
      }

      & > span:nth-child(2) {
        font-size: 18px;
        line-height: 36px;
        font-weight: 400;
        margin-top: 12px;
        opacity: 50%;
      }

      main {
        display: flex;
        margin-top: 20px;

        .business_model_main_left {
          display: flex;
          flex-direction: column;

          .business_model_active {
            background: linear-gradient(
              132deg,
              rgba(255, 96, 90, 0.1) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            box-shadow: inset 19px 9px 20px 4px rgba(133, 35, 0, 0.01);
            border-radius: 8px;
            color: rgba(253, 33, 42, 1);

            &::before {
              content: "";
              background: linear-gradient(180deg, #ff605a 0%, #ff0010 100%);
              box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
              border-radius: 10px 0px 0px 10px;
              position: absolute;
              left: 0;
              top: 0;
              width: 6px;
              height: 100%;
            }
          }

          & > span {
            width: 260px;
            height: 120px;
            margin-top: 25px;
            background: #ffffff;
            border-radius: 8px;
            text-align: center;
            line-height: 120px;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            position: relative;

            &:hover {
              background: linear-gradient(
                132deg,
                rgba(255, 96, 90, 0.1) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              box-shadow: inset 19px 9px 20px 4px rgba(133, 35, 0, 0.01);
              border-radius: 8px;
              color: rgba(253, 33, 42, 1);
            }

            &::before {
              content: "";
              background: linear-gradient(180deg, #ff605a 0%, #ff0010 100%);
              box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
              border-radius: 10px 0px 0px 10px;
              position: absolute;
              left: 0;
              top: 0;
              width: 6px;
              height: 0;
              transition: all 0.2s linear;
            }

            &:hover::before {
              content: "";
              background: linear-gradient(180deg, #ff605a 0%, #ff0010 100%);
              box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
              border-radius: 10px 0px 0px 10px;
              position: absolute;
              left: 0;
              top: 0;
              width: 6px;
              height: 100%;
            }
          }
          & > span:first-child {
            margin-top: 30px;
          }
        }

        .business_model_main_right {
          width: 960px;
          height: 664px;
          margin-left: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .cooperation_requirements {
      & > div {
        img {
          width: 100%;
          vertical-align: middle;
        }
      }
      .cooperation_requirements_bottom {
        position: relative;
        & > a {
          position: absolute;
          left: 212px;
          top: 268.5px;
          width: 210px;
          height: 54px;
          background: linear-gradient(140deg, #ff605a 0%, #ff0010 100%);
          box-shadow: inset 19px 9px 20px 4px rgba(0, 66, 133, 0.01);
          border-radius: 27px;
          backdrop-filter: blur(0px);
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          line-height: 54px;
          text-align: center;
          cursor: pointer;

          &::before {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .about_us {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 60px;

      & > span:first-child {
        font-size: 42px;
        line-height: 59px;
        font-weight: 500;
        padding-top: 50px;
      }

      & > span:nth-child(2) {
        font-size: 18px;
        line-height: 36px;
        font-weight: 400;
        opacity: 50%;
        margin-top: 12px;
      }

      .about_us_map {
        display: flex;
        flex-direction: column;
        width: 1200px;
        margin-top: 36px;
        img {
          width: 100%;
          height: 470px;
        }
        .address {
          height: 128px;
          background-color: rgba(0, 0, 0, 1);
          padding: 33px 0 0 50px;
          color: #ffffff;
          font-weight: 400;
          display: flex;

          .address_one,
          .address_two,
          .address_three {
            span:first-child {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              line-height: 18px;
            }

            span:nth-child(2) {
              font-size: 16px;
              line-height: 30px;
              margin-top: 14px;
            }
          }

          .address_two {
            margin-left: 171px;
          }
          .address_three {
            margin-left: 234px;
          }
        }
      }

      .join_us {
        width: 1200px;
        height: 260px;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: url("https://jumyoux-ali-oss-bucket.oss-cn-hangzhou.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/weitu1%402x.png")
          no-repeat;
        background-size: 100% 100%;
        color: #ffffff;

        & > span:first-child {
          font-size: 32px;
          line-height: 45px;
          font-weight: 400;
        }

        & > span:nth-child(2) {
          font-size: 16px;
          line-height: 41px;
          font-weight: 500;
          margin-top: 32px;
          width: 165px;
          height: 43px;
          position: relative;
          text-align: center;
          z-index: 999;
          color: #ffffff;
          border-radius: 10px;
          cursor: pointer;
          border: 2px solid #ffffff;
          display: flex;
          justify-content: center;
          transition: all 0.3s linear;

          &:hover {
            color: #000000;
            border: 2px solid transparent;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 0;
            height: 100%;
            border-radius: 10px;
            z-index: -1;
            transition: all 0.3s linear;
          }

          &:hover::before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background-color: #ffffff;
            border: 2px solid #ffffff;
            z-index: -1;
          }
        }
      }
    }
  }
  footer {
    margin-top: 60px;
    padding-bottom: 40px;
    display: flex;
    width: 1200px;
    justify-content: space-between;

    .footer_left {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      img {
        width: 140px;
        height: 54px;
      }

      span {
        margin-top: 23px;
        opacity: 0.6;
        white-space: nowrap;
      }

      a {
        margin-top: 10px;
        opacity: 0.6;
      }
    }

    .footer_center,
    .footer_right {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        white-space: nowrap;
      }

      & > span:first-child {
        width: 72px;
        height: 25px;
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
      }

      & > span:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2px 6px 0px rgba(46, 0, 0, 0.3);
        border-radius: 2px;
        margin-top: 16px;
        img {
          width: 95px;
          height: 95px;
        }
      }

      & > span:nth-child(3) {
        width: 131px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        margin-top: 16px;
        opacity: 0.6;
      }
    }
    .footer_center {
      margin-left: 468px;
    }
    .footer_right {
      margin-left: 111px;
    }
  }
}
</style>
